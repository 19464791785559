export const ENV = {
	DEVTEST: 'DEVTEST',
	PROD: 'FUT',
	PROD_AWS: 'AWS_FUT',
	AWS_DEV: 'AWS_DEV',
	LOCAL: 'LOCAL',
};
// uncomment this line and set 'DEVTEST'/'FUT' to change environment quickly.
// ensure this line is commented before any git commit
// export const API_ENV = 'AWS_DEV'

// get environment by cli arg
// ensure this line isn't commented before any git commit
export const API_ENV = __CODING_BAR_API_ENV__;

const _apiUrl = {
	[ENV.DEVTEST]: {
		MEOWBIT: 'https://meowbit-codinabr-ai.firebaseapp.com/',
		MICROBIT: 'https://api.codingbar.ai/microbit/index.html',
		PAY: 'https://api.codingbar.ai/dev/service/stage', // dev, do not merge this line
		DATABASE: 'https://api.codingbar.ai:8001',
		HOST: 'https://codebar-dev.web.app',
	},
	[ENV.PROD]: {
		MEOWBIT: 'https://meowbit-codinabr-ai.firebaseapp.com/',
		MICROBIT: 'https://api.codingbar.ai/microbit/index.html',
		PAY: 'https://api.codingbar.ai/service',
		DATABASE: 'https://api.codingbar.ai:8001',
		HOST: 'https://app.codingbar.ai',
	},
	[ENV.PROD_AWS]: {
		MEOWBIT: 'https://meowbit-codinabr-ai.firebaseapp.com/',
		MICROBIT: 'https://api.codingbar.ai/microbit/index.html',
		PAY: 'https://api.codingbar.ai/service',
		DATABASE: 'https://api.codingbar.ai:8001',
		HOST: 'https://app.codingbar.ai',
	},
	[ENV.LOCAL]: {
		MEOWBIT: 'https://meowbit-codinabr-ai.firebaseapp.com/',
		MICROBIT: 'http://api.codingbar.ai/microbit/index.html',
		PAY: 'http://localhost:12345/stage',
		DATABASE: 'http://localhost:12345',
		HOST: 'http://localhost:8080',
	},
	[ENV.AWS_DEV]: {
		MEOWBIT: 'https://meowbit-codinabr-ai.firebaseapp.com/',
		MICROBIT: 'https://api.codingbar.ai/microbit/index.html',
		PAY: 'https://api2.codingbar.ai/dev/service/stage',
		DATABASE: 'https://api.codingbar.ai:8001',
		HOST: 'https://codebar-dev.firebaseapp.com',
	},
	DEFAULT: {
		MEOWBIT: 'https://meowbit-codinabr-ai.firebaseapp.com/',
		MICROBIT: 'https://api.codingbar.ai/microbit/index.html',
		PAY: 'https://api.codingbar.ai/service',
		DATABASE: 'https://api.codingbar.ai:8001',
		HOST: 'https://app.codingbar.ai',
	},
};
export const API_URL = _apiUrl[API_ENV] ? _apiUrl[API_ENV] : _apiUrl.DEFAULT;

export const LINE_QRCODE_URL = 'https://line.me/R/ti/p/%40ply0218v';

export const STUFF_MAIL_LIST = [
	'tengjie.huang@airabbi.com',
	'jerry@airabbi.com',
	'april@airabbi.com',
	'tina.chen@airabbi.com',
];
// export const STUFF_MAIL_LIST = ['porkchopwork423@gmail.com']

export function getVersion() {
	return 'v3.3.72 20250408';
}

export function getDesktopVersions() {
	return [
		{
			version: '2.2',
			links: [
				{
					os: 'windows',
					osVersion: '64bit',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1wSEItb5zjnTp3NFh74xiCAmwMDuZ5O3I', //change/reBuild 20220822
				},
				{
					os: 'mac',
					osVersion: '11',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/13OF0S2lxaDHNb72kcsUwGiBMnXRAcsEp/view',
				},
				{
					os: 'mac',
					osVersion: '10.13',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1xzAXKEeL6UxQ0y4iwBgaYTw3xodsUtNT',
				},
			],
		},
		{
			version: '2.1',
			links: [
				{
					os: 'windows',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1-y1mxO4kSTTw-xqdLTATUXsxEVkKfN3g',
				},
				{
					os: 'windows',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/1-y1mxO4kSTTw-xqdLTATUXsxEVkKfN3g',
				},
				{
					os: 'mac',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1xzAXKEeL6UxQ0y4iwBgaYTw3xodsUtNT',
				},
				{
					os: 'mac',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/1xzAXKEeL6UxQ0y4iwBgaYTw3xodsUtNT',
				},
			],
		},
		{
			version: '2.0',
			links: [
				{
					os: 'windows',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1P04tplo25lQ9MqGMTzyfmQUicsuV7W88',
				},
				{
					os: 'windows',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/1P04tplo25lQ9MqGMTzyfmQUicsuV7W88',
				},
				{
					os: 'mac',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/15_a9vea27vaCUt1LVQgO2sLH1eiNDMY3',
				},
				{
					os: 'mac',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/15_a9vea27vaCUt1LVQgO2sLH1eiNDMY3',
				},
			],
		},
		{
			version: '1.7',
			links: [
				{
					os: 'windows',
					feature: 'Generic',
					url: 'https://drive.google.com/open?id=1d00EwMwI1Sd0JX5jq1qvvMs_RlWOMR2j',
				},
				{
					os: 'windows',
					feature: 'DataScience',
					url: 'https://drive.google.com/open?id=1d00EwMwI1Sd0JX5jq1qvvMs_RlWOMR2j',
				},
				{
					os: 'mac',
					feature: 'Generic',
					url: 'https://drive.google.com/open?id=1RjJq7n42ANKOhJPh0LVMWWCwyrLMC0iz',
				},
				{
					os: 'mac',
					feature: 'DataScience',
					url: 'https://drive.google.com/open?id=1RjJq7n42ANKOhJPh0LVMWWCwyrLMC0iz',
				},
			],
		},
		{
			version: '1.6',
			links: [
				{
					os: 'windows',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1Hx8g1uzGsSUyc8EHt_O08qBqPVAjFSot/view?usp=sharing',
				},
				{
					os: 'windows',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/1Hx8g1uzGsSUyc8EHt_O08qBqPVAjFSot/view?usp=sharing',
				},
				{
					os: 'mac',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1_86dL5cIeioD-WklPmFfO-eOTEnGbD-E/view?usp=sharing',
				},
				{
					os: 'mac',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/1_86dL5cIeioD-WklPmFfO-eOTEnGbD-E/view?usp=sharing',
				},
			],
		},
		{
			version: '1.5',
			links: [
				{
					os: 'windows',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1PyJ1tO6rNVaynHmINqX798W3KIout7Bz/view?usp=sharing',
				},
				{
					os: 'windows',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/1PyJ1tO6rNVaynHmINqX798W3KIout7Bz/view?usp=sharing',
				},
				{
					os: 'mac',
					feature: 'Generic',
					url: 'https://drive.google.com/file/d/1f2nYDrgzfGm-cvq6R1Q4O46TI8o4g6dz/view?usp=sharing',
				},
				{
					os: 'mac',
					feature: 'DataScience',
					url: 'https://drive.google.com/file/d/1f2nYDrgzfGm-cvq6R1Q4O46TI8o4g6dz/view?usp=sharing',
				},
			],
		},
		{
			version: '1.4',
			links: [
				{
					os: 'windows',
					feature: 'Generic',
					url: 'https://drive.google.com/open?id=11to-1a6_owyTVPBIiM41Z-ZX2NCKSYmd&export=download',
				},
				{
					os: 'windows',
					feature: 'DataScience',
					url: 'https://drive.google.com/open?id=1WCRyzyXF55ttTs3KpBJRVgPEYh1l1kVE&export=download',
				},
				{
					os: 'mac',
					feature: 'Generic',
					url: 'https://drive.google.com/open?id=1-9rZEhmGh90e5JApqX3pert28waUB232&export=download',
				},
				{
					os: 'mac',
					feature: 'DataScience',
					url: 'https://drive.google.com/open?id=1x5Iws0X56pehIzwJ_YkgCxKlVnDo0rb7&export=download',
				},
			],
		},
		{
			version: '1.3',
			links: [
				{
					os: 'windows',
					feature: 'Generic',
					url: 'https://drive.google.com/a/airabbi.com/uc?id=1TA1QNVUWPtZwU4zF1ecNWEASAc3oknGQ&export=download',
				},
				{
					os: 'windows',
					feature: 'DataScience',
					url: 'https://drive.google.com/a/airabbi.com/uc?id=1tp3GSLnkAI8VzC70RLkPMJ062w0ohWQ2&export=download',
				},
				{
					os: 'mac',
					feature: 'Generic',
					url: 'https://drive.google.com/a/airabbi.com/uc?id=1RMcAqYAZHRFHsP7sLmkvIoXDcr8Xy6Z3&export=download',
				},
				{
					os: 'mac',
					feature: 'DataScience',
					url: 'https://drive.google.com/a/airabbi.com/uc?id=1tWpTUoQYEZ6Hfo9stxt3qGld6YPPpr7h&export=download',
				},
			],
		},
	];
}

export function getCurrentDesktopVersion() {
	return '2.1';
}

export function getSpeedTimeThreshold() {
	return 1000;
}
